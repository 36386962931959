import { WindowLocation } from "@reach/router";
import {
  Button,
  EmptyLayout,
  LittersSuccess as Success,
  Loader,
  PageTitle,
  Wrapper,
} from "@royalcanin-fr-loyalty/ui-kit";
import { isFuture } from "date-fns";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { Layout } from "../../components/Layout";
import { useLitter } from "../../lib/hooks/useLitters";
import { useLocationContext } from "../../lib/LocationProvider";
import { withPage } from "../../lib/withPage";
import Error404 from "../404";

const LittersSuccess = ({ location }: { location: WindowLocation<any> }) => {
  const {
    litter: litterId,
    kitCreated,
    adCreated,
    backUrl,
  } = useLocationContext().query;
  const { litter, loading: litterLoading } = useLitter(litterId);
  const title = <PageTitle center>Déclaration enregistrée</PageTitle>;
  const LayoutComponent = backUrl ? EmptyLayout : undefined;
  const layoutProps = { LayoutComponent };

  if (litterLoading) {
    return (
      <Layout {...layoutProps}>
        <Wrapper>
          {title}
          <Loader />
        </Wrapper>
      </Layout>
    );
  }

  if (!litter) {
    return <Error404 location={location} {...layoutProps} />;
  }

  const ad =
    adCreated &&
    litter.publicationDateEnd &&
    isFuture(new Date(litter.publicationDateEnd))
      ? {
          publicationDateEnd: litter.publicationDateEnd,
        }
      : undefined;

  const kits = kitCreated && !!litter.lastPurchase;

  return (
    <Layout {...layoutProps}>
      <Wrapper>
        {title}
        <Row center="lg">
          <Col lg={8}>
            <Success ad={ad} kits={kits} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              {backUrl ? (
                <Button href={backUrl}>Retour</Button>
              ) : (
                <Button href="/litters">Mes portées</Button>
              )}
            </div>
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

export default withPage(LittersSuccess, {
  title: "Portées",
});
